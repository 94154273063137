/* eslint-disable react/prop-types */
import React from 'react'
import { Carousel } from 'react-bootstrap'

const Controlledcarousel = ({ index, onSelect, images, title, visible }) => {
  return (
    <div className={`col-xl-4 ${visible && 'fadeIn'} showcaseCarousel`} style={!visible ? { opacity: 0 } : {}}>
    <h4 className="showCaseTitle">
        {title}
    </h4>
    <Carousel activeIndex={index} onSelect={onSelect} interval={null} controls={false} fade={true} indicators={false}>
        {images.map((image) => (
            <Carousel.Item key={image.default.split('.')[1]}>
                <img
                src={image.default}
                className="IOImages"
                />
            </Carousel.Item>
        ))}
    </Carousel>
    </div>
  )
}

export default Controlledcarousel
