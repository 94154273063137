import React from 'react'
import './index.css'
import App from './App'
import { BrowserRouter } from 'react-router-dom'
import * as ReactDOM from 'react-dom'
/* eslint-disable */
window.fbAsyncInit = function () {
  window.FB.init({
    appId: process.env.FACEBOOK_CLIENT_ID,
    cookie: false,
    xfbml: false,
    version: 'v18.0'
  })
  window.FB.AppEvents.logPageView()
  window.FBInitialized = true
  var fbInitEvent = new Event('FBObjectReady');
  document.dispatchEvent(fbInitEvent);
};



(function (d, s, id) {
  let js; const fjs = d.getElementsByTagName(s)[0]
  if (d.getElementById(id)) { return }
  js = d.createElement(s); js.id = id
  js.src = 'https://connect.facebook.net/en_US/sdk.js'
  fjs.parentNode.insertBefore(js, fjs)
}(document, 'script', 'facebook-jssdk'))


ReactDOM.render(
      <React.StrictMode>
          <link
            rel="stylesheet"
            href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.0/css/bootstrap.min.css"
            integrity="sha384-9aIt2nRpC12Uk9gS9baDl411NQApFmC26EwAOH8WgZl5MYYxFfc+NcPb1dKGj7Sk"
            crossOrigin="anonymous"
          />
        <BrowserRouter>
        <App />
        </BrowserRouter>
      </React.StrictMode>,
      document.getElementById('root')
)
