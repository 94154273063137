/* eslint-disable react/prop-types */
import React, { useEffect, useRef } from 'react'
import button from './pngfind.com-cross-png-609333.png'
import './transformation.css'

const _StyleProcess = ({ process, set, procs, remove, index, hide }) => {
  let processDisplay = ''
  const del = () => {
    set(procs.current.filter((item) => item.key !== process.key))
  }
  const ref = useRef()
  useEffect(() => {
    ref.current.scrollIntoView({ behavior: 'smooth' })
  }, [])
  if (process.data.status === 'PENDING') {
    processDisplay = (
        <div style={{ width: '100%' }} className="border border-warning procborder">
          Queued {process.data.styleName} to be processed.
        </div>
    )
  } else if (process.data.status === 'STARTED') {
    processDisplay = (
        <div className="border border-primary procborder" style={{ width: '100%' }}>
          Processing style: {process.data.styleName}.
        </div>
    )
  } else if (process.data.status === 'FINALIZING') {
    processDisplay = (
        <div className="border border-info" style={{ width: '100%' }}>
          Finalizing style: {process.data.styleName}.
        </div>
    )
  } else if (process.data.status === 'FAILURE') {
    processDisplay = (
          <div className="border border-danger procborder" style={{ width: '100%', position: 'relative' }}>
            Process {process.data.styleName} failed.
            <button className="deleteButton" onClick={() => remove(process.key)} style={{ background: 'transparent', position: 'absolute', right: '20px' }}>
                <img src={button} height="15px" width="15px"></img>
              </button>
          </div>
    )
  } else if (process.data.status === 'SUCCESS') {
    processDisplay = (
          <div className="border border-success procborder" style={{ width: '100%', position: 'relative' }}>
              {process.data.styleName} has been added to your styles.
              <button className="deleteButton" onClick={del} style={{ background: 'transparent', position: 'absolute', right: '20px' }}>
                <img src={button} height="15px" width="15px"></img>
              </button>
          </div>
    )
  }

  return (
        <div ref={ref} className={`barcontainer ${hide ? 'hide' : ''}`} style={{ transform: `translateY(calc(-${100 * index}% - 10px))` }}>
          {processDisplay}
        </div>
  )
}

export const StyleProcess = React.memo(_StyleProcess)
