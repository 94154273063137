/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Nav, Navbar } from 'react-bootstrap'
import { Link, Route, Switch, useHistory, withRouter } from 'react-router-dom'
import './App.css'
import Home from './Client/Home'
import { FaFacebook, FaInstagram } from 'react-icons/fa'
import ContactUs from './Client/Contact'
import logo from './logo.ico'
import Terms from './Client/TOS'
import { ErrorPage } from './Client/404'
import Profile from './Client/Profile'
import Transformation from './Client/Transformation'
import Login from './Client/Login'
import DataDeleter from './components/data_delete'
const PROXY = process.env.PROXY

// eslint-disable-next-line react/prop-types
const clearUser = () => ({ token: '', authServer: '', expiry: 0 })
function parseJwt (token) {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
  }).join(''))

  return JSON.parse(jsonPayload)
}
const App = ({ user, location }) => {
  const [{ token, authServer, expiry }, setAuthInfo] = useState(clearUser)
  const [name, setName] = useState('')
  const [allStyles, setAllStyles] = useState({})
  const [navStyle, setNavStyle] = useState({ background: 'transparent' })
  const [login, setLogin] = useState(false)
  const [attemptedOAuth, setAttemptedOAuth] = useState(false)
  const [requestingTransformationPage, setRequestingTransformationPage] = useState(false)
  const history = useHistory()
  const [expanded, setExpanded] = useState(false)

  const fetchApiAuthenticated = useCallback((route, init = { headers: {} }, _authServer = authServer, _token = token) => {
    if (!init.headers) {
      init.headers = {}
    }
    init.headers.AuthenticationServer = _authServer
    init.headers.Authorization = `Bearer ${_token}`
    return fetch(`${PROXY}${route}`, init).then((res) => {
      if (res.status !== 401) {
        return res
      } else {
        if (res.status === 401) {
          setUser()
          history.push('/')
          alert('Unauthorized access')
          throw Error('Unauthorized access')
        }
      }
    }).catch((err) => console.log(err)
    )
  }, [token, authServer])

  useEffect(() => {
    if (attemptedOAuth && !token && (history.location.pathname === '/transformation' || history.location.pathname === '/profile')) {
      history.push('/')
    }
  }, [attemptedOAuth])

  useEffect(() => {
    if (token && expiry) {
      const timeout = setTimeout(() => {
        if (authServer === 'google') {
          window.google.accounts.id.prompt((notification) => {
            if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
              removeLocalStorage()
              logout()
            }
          })
        } else if (authServer === 'facebook') {
          window.FB.getLoginStatus(FBListener, true)
        }
      }
      , expiry)
      return () => {
        clearTimeout(timeout)
      }
    }
    return () => undefined
  }, [token, expiry])

  const setLocalStorage = (authClient, expiresAt, storeToken) => {
    window.localStorage.setItem('auth_client', authClient)
    window.localStorage.setItem('expires_at', expiresAt)
    window.localStorage.setItem('token', storeToken)
  }

  const removeLocalStorage = () => {
    window.localStorage.removeItem('auth_client')
    window.localStorage.removeItem('expires_at')
    window.localStorage.removeItem('token')
    window.localStorage.removeItem('name')
  }

  const setUser = () => {
    setName('')
    setAllStyles({})
    setAuthInfo(clearUser)
    removeLocalStorage()
  }
  const logout = () => {
    if (authServer === 'facebook') {
      window.FB.logout()
    }
    if (authServer === 'google') {
      window.google.accounts.id.revoke(parseJwt(token).sub)
    }
    // window.localStorage.removeItem('token')
    // window.localStorage.removeItem('expiry')
    setUser()
    history.push('/')
  }
  const handleLoginClick = () => {
    document.body.style.overflow = 'hidden'
    setExpanded(false)
    setLogin(true)
  }
  const handleLoginClickTransform = () => {
    document.body.style.overflow = 'hidden'
    setLogin(true)
    setRequestingTransformationPage(true)
    setExpanded(false)
  }
  const handleLoginClose = () => {
    document.body.style.overflow = 'auto'
    setLogin(false)
  }

  const background = useRef()

  useEffect(() => {
    let loggedIn = false
    if (!window.localStorage.getItem('auth_client')) {
      setAttemptedOAuth(true)
    } else {
      const expiresIn = window.localStorage.getItem('expires_at') - new Date()
      console.log(expiresIn)
      if (expiresIn > 60000) {
        setAuthInfo({ expiry: expiresIn, authServer: window.localStorage.getItem('auth_client'), token: window.localStorage.getItem('token') })
        setName(localStorage.getItem('name'))
        setAttemptedOAuth(true)
        loggedIn = true
      }
    }

    window.onload = () => {
      window.google.accounts.id.initialize({
        client_id: process.env.GOOGLE_CLIENT_ID,
        // scope: 'https://www.googleapis.com/auth/userinfo.profile',
        auto_select: true,
        callback: (response) => {
          const { exp } = parseJwt(response.credential)
          if (exp) {
            const _expiry = Math.max(0, ((exp * 1000) - new Date()) - 1000)
            console.log(_expiry)
            setAuthInfo({ expiry: _expiry, authServer: 'google', token: response.credential })
            setLocalStorage('google', exp * 1000, response.credential)
            setAttemptedOAuth(true)
            setName('Google')
            fetch(`https://oauth2.googleapis.com/tokeninfo?id_token=${response.credential}`).then((res) => {
              if (res.ok) {
                return res.json()
              }
            }).then((data) => {
              window.localStorage.setItem('name', data.name)
              setName(data.name)
            }
            )
          } else {
            window.localStorage.removeItem('auth_client')
          }
        }
      })
      if (!loggedIn && window.localStorage.getItem('auth_client') === 'google') {
        window.google.accounts.id.prompt((notification) => {
          if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
            removeLocalStorage()
            setAttemptedOAuth(true)
          }
        })
      }
    }
    if (!loggedIn && window.localStorage.getItem('auth_client') === 'facebook') { window.FBInitialized ? window.FB.getLoginStatus(FBListener) : document.addEventListener('FBObjectReady', () => window.FB.getLoginStatus(FBListener)) }
  }, [])

  const FBListener = (response) => {
    if (response.authResponse) {
      const _token = response.authResponse.accessToken
      setLocalStorage('facebook', Number(new Date()) + response.authResponse.expiresIn * 1000, _token)
      setAuthInfo({ token: _token, authServer: 'facebook', expiry: Math.max(0, (response.authResponse.expiresIn * 1000 - 10)) })
      setAttemptedOAuth(true)
      setName('Facebook')
      fetch(`https://graph.facebook.com/me?access_token=${_token}`).then((res) => {
        if (res.ok) {
          return res.json()
        }
      }
      ).then((data) => {
        localStorage.setItem('name', data.name)
        setName(data.name)
      }).catch((e) => {
        console.log(e)
      })
    } else {
      removeLocalStorage()
      setAttemptedOAuth(true)
    }
  }

  useEffect(() => {
    if (token) {
      if (requestingTransformationPage) {
        setRequestingTransformationPage(false)
        history.push('/transformation')
      }
      if (login) {
        handleLoginClose()
      }
    }
  }, [token])

  useEffect(() => {
    if (!location.hash && !login) {
      document.body.scrollTo(0, 0)
    }
    if (background.current && history.location.pathname === '/') {
      const options = { rootMargin: '-150px' }
      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
          setNavStyle(entry.isIntersecting ? { background: 'transparent' } : { background: '#396f82' })
        })
      }, options)
      observer.observe(background.current)
      return () => {
        observer.disconnect()
      }
    } else {
      setNavStyle({ background: '#396f82' })
    }
  }, [location])

  return (
      <div className="App">
        {
          login && <Login FBListener={FBListener} handleLoginClose={handleLoginClose} cancel={handleLoginClose}/>
        }
        <Navbar expanded={expanded} className="navbar-dark navigationbar fixed-top" expand="lg" style={navStyle}>
          <Navbar.Brand as={Link} to="/"><img style={{ display: 'inline', marginRight: '10px', position: 'relative', bottom: '4px' }} width="25px" height="30px" src={logo}/><h3 style={{ display: 'inline' }}>Dionyziuz</h3></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(!expanded)} />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto" >
              <Nav.Item>
                {token ? <Nav.Link as={Link} onClick={() => setExpanded(false)} className="navItem" to="/transformation"><span>Transform</span></Nav.Link> : <Nav.Link href="#" onClick={handleLoginClickTransform} ><span>Transform</span></Nav.Link>}
              </Nav.Item>
              <Nav.Item>
                <Nav.Link onClick={() => setExpanded(false)} as={Link} className="navItem" to="/contact-us"><span>Contact us</span></Nav.Link>

              </Nav.Item>
              <Nav.Item>
                <Nav.Link onClick={() => setExpanded(false)} className="navItem" href="https://www.facebook.com/dionyziuz/"><span><FaFacebook/></span></Nav.Link>

              </Nav.Item>
              <Nav.Item>
                <Nav.Link onClick={() => setExpanded(false)} className="navItem" href="https://www.instagram.com/dionyziuz/"><span><FaInstagram/></span></Nav.Link>
              </Nav.Item>

            </Nav>
            <Nav className="user-nav">
                {attemptedOAuth && (!token
                  ? (
                    <Nav.Item>
                      <button onClick={handleLoginClick} className="loginbutton userinfobutton btn btn-dark" role="button">Login/ Register</button>
                    </Nav.Item>
                    )
                  : <Nav.Item>
                    <Link onClick={() => setExpanded(false)} className="nav-link userinfobutton m-auto btn btn-clear" to="/profile"><span>{name}</span></Link>
                    </Nav.Item>)
                }

            </Nav>
          </Navbar.Collapse>
        </Navbar>
        {history.location.pathname !== '/' && <div className="container" style={{ height: '61.92px' }}></div>}
          <Switch>
            <Route exact path="/">
              <Home ref={background} loginClick={handleLoginClickTransform} user={token}/>
            </Route>
            <Route exact path ="/tos">
              <Terms token={token} fetchApi={fetchApiAuthenticated} logout={logout}/>
            </Route>
            <Route exact path="/contact-us">
              <ContactUs/>
            </Route>
            <Route exact path="/transformation" >
               <Transformation allStyles={allStyles} setAllStyles={setAllStyles} fetchApi={fetchApiAuthenticated} authenticated={token} />
            </Route>
            <Route exact path="/profile" >
              <Profile logoutUser={logout} _token={token} name={name} fetchApi={fetchApiAuthenticated}/>
            </Route>
            <Route exact path="/deletedata">
            <DataDeleter token={token} logoutUser={logout}/>
            </Route>
            <Route path='*' exact={true} component={ErrorPage}/>
          </Switch>
      </div>
  )
}

export default withRouter(App)
