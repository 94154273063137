import React, { useState } from 'react'
const PROXY = process.env.PROXY

const ContactUs = () => {
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')

  const contactSubmit = (e) => {
    if (message.length < 500) {
      fetch(`${PROXY}/contactus`, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ email: email, message: message }) }).then((res) => {
        if (res.ok) {
          res.json()
        } else {
          throw Error()
        }
      }).then((data) => {
        setEmail('')
        setMessage('')
        alert('Contact request has been submitted!')
      }).catch(() => {
        alert('Contact request has failed')
      })
    } else {
      alert("Message can't be longer than 500 characters.")
    }
    e.preventDefault()
  }

  return (
        <div className="container-fluid" style={{ marginTop: '100px', marginBottom: '40px' }} id='bgcontact'>
            <div className="container" id="contactcontain">
                <div className="row shadow" id="contactus" style={{ opacity: '0.99', padding: '30px' }}>
                    <article className="col-md-6" style={{ padding: '30px' }} id="contactarticle">
                        <h1 className="border-bottom border-dark" style={{ paddingBottom: '10px' }}>Contact us!</h1>
                        <p style={{ paddingTop: '10px' }}>
                            If you are interested in becoming a tester of our platform which is not live yet please leave us a message.
                        </p>
                    </article>

                    <form className="col-md-6" onSubmit={contactSubmit} id="contactform" style={{ paddingTop: '20px', textAlign: 'left' }}>
                        <div className="form-group">
                            <label htmlFor="" >Email:</label>
                            <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} className="form-control" id="email" required/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="message" >Message:</label>
                            <textarea required id="message" value={message} onChange={(e) => setMessage(e.target.value)} className="form-control"></textarea>
                        </div>
                        <button className="btn bg-transparent" style={{ border: 'grey solid 1px' }}>Send!</button>
                    </form>
                </div>
            </div>
        </div>
  )
}

export default ContactUs
