/* eslint-disable react/prop-types */
import { Carousel, Dropdown } from 'react-bootstrap'
import React, { useEffect, useState, Fragment, useRef } from 'react'
// import ControlledCarousel from './controlledcarousel'
import './transformation.css'
import ProcessBar from './processbar'
import { StyleProcess } from './styleproccessbar'
// import NavigationBar from './navbar'

const GUARRANTEEDTYPES = ['Your styles', 'Upload style']
const TEXT = <p>Upload art styles for creating new transformation models. This could be your own style or an existing classical art work. We will create a model of that style embodying the color palette and brush strokes. Higher resolution styles will yield better results but the maximum is 2500 pixels (either side). Contact us for specialized settings and higher resolution results.</p>
const MAX_PROCS = 5
const MAX_IMAGES = 2
window.global = []
const global = window.global

function capitalizeFirstLetter (string) {
  const stringArray = string.split('.')
  const processedArray = stringArray.map((word) => capitalizeWord(word))
  return processedArray.join(' ')
}

function capitalizeWord (word) {
  return word.charAt(0).toUpperCase() + word.slice(1)
}

const PROXY = process.env.PROXY
const Transformation = ({ allStyles, setAllStyles, fetchApi, authenticated }) => {
  const [index, setIndex] = useState(0)
  const [personalIndex, setPersonalIndex] = useState(0)
  const [personalStyles, setPersonalStyles] = useState({})
  const [stylesType, setStylesType] = useState('Favorites')
  const [actualImages, setActualImages] = useState([])
  const [actualImageNames, setActualImageNames] = useState([])
  const [processes, setProcesses] = useState([])
  const [inputPersonalStyle, setInputPersonalStyle] = useState({})
  const [inputImage, setInputImage] = useState('')
  const [inputStyle, setInputStyle] = useState('')
  const [remountCount, setRemountCount] = useState(0)
  // Requesting a transformation
  const [requesting, setRequesting] = useState(false)
  // Requesting a style creation
  const [requestingProcesses, setRequestingProcesses] = useState(false)
  const [requestingTrainStyle, setRequestingTrainStyle] = useState(false)
  const [requestingPersonal, setRequestingPersonal] = useState(false)
  const [abstractionLevel, setAbstractionLevel] = useState('5')

  // holds up to date processes for callbacks
  const instantProcesses = useRef()
  instantProcesses.current = processes
  const [downloading, setDownloading] = useState(false)

  const refresh = () => setRemountCount(remountCount + 1)

  const useAuthEffect = (effect, dependencies) => {
    useEffect(() => { authenticated && effect() }, [authenticated, ...dependencies])
  }

  const removeProcess = (key) => {
    const transitionRemovalArray = [...instantProcesses.current]
    transitionRemovalArray.forEach((proc) => (proc.key === key) && (proc.hide = true))
    global.push(transitionRemovalArray[0])
    setProcesses(transitionRemovalArray)
    setTimeout(() => {
      setProcesses([...instantProcesses.current].filter((proc) => proc.key !== key))
    }, 1000)
  }

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex)
  }
  const _handleSelect = (selectedIndex, e) => {
    setPersonalIndex(selectedIndex)
  }
  const changeAbstraction = (e) => {
    setAbstractionLevel(e.target.value)
  }

  const handleClear = () => {
    setActualImages([])
    setActualImageNames([])
    setInputImage('')
  }

  useEffect(() => {
    setIndex(0)
  }, [stylesType])

  useAuthEffect(() => {
    // used for fetchApiing all of the personal styles, that have been succesfully trained for a user
    let timeout
    if (!Object.keys(allStyles).length) {
      fetchApi('/api/action/styles', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      }).then((res) => {
        if (res.ok) {
          return res.json()
        } else {
          throw Error()
        }
      }).then((data) => {
        // { stylesType: [[links], "Style info"]}
        setAllStyles(data)
      }).catch(() => {})
    }

    if (!personalStyles.length) {
      setRequestingPersonal(true)
      fetchApi('/api/action/userstyles', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then((res) => {
          if (res.ok) {
            return res.json()
          } else {
            throw Error()
          }
        }
        )
        .then((data) => {
          setRequestingPersonal(false)
          if (!data.error) {
            if (Object.keys(data).length) {
              setPersonalStyles(data)
            }
          }
        }).catch(() => {})
    }

    if (!processes.length) {
      setRequestingProcesses(true)
      fetchApi('/api/process/all', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      }).then((res) => {
        if (res.ok) {
          return res.json()
        } else {
          throw Error()
        }
      }).then((data) => {
        setRequestingProcesses(false)
        if (data.length) {
          console.log(data)
          setProcesses(data)
        }
      }).catch(() => { setRequestingProcesses(false) })
    }
    return () => {
      clearTimeout(timeout)
    }
  }, [])

  // used for fetchApiing status of the transformation and style processes
  useAuthEffect(() => {
    if (processes.length) {
      // Dont check up on finished and failed
      const unfinished = processes.filter((process) => process.data.status !== 'SUCCESS' && process.data.status !== 'FAILURE')
      // Get the keys for the query
      const postData = unfinished.map((process) => process.key)
      const myAbortController = new AbortController()
      if (postData.length) {
        const requestInterval = setTimeout(() => {
          setRequestingProcesses(true)
          fetchApi('/api/process/all', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            }
          }).then((res) => {
            if (res.ok) {
              return res.json()
            } else {
              throw Error()
            }
          }).then((data) => {
            setRequestingProcesses(false)
            if (data.length) {
              console.log(data)
              setProcesses(data)
            }
          }).catch(() => { setRequestingProcesses(false) })
        }, 30000)
        return () => {
          clearTimeout(requestInterval)
          myAbortController.abort()
        }
      }
    }
  }, [processes])

  // Handles upload of input images
  const handleUpload = (e) => {
    const files = e.target.files
    if (files) {
      const _actualImageNames = []
      let imageCount = actualImageNames.length
      const _actualImages = []
      const promiseCreation = (file) => new Promise((resolve, reject) => {
        if (++imageCount > MAX_IMAGES) {
          resolve()
        }
        if (file.type !== 'image/jpeg') {
          alert('JPEG images only')
          resolve()
        }
        const reader = new FileReader()
        reader.onloadend = () => {
          _actualImageNames.push(file.name)
          _actualImages.push(reader.result)
          resolve()
        }
        reader.onerror = () => {
          resolve()
        }
        if (file instanceof Blob) {
          setInputImage(e.target.value)
          reader.readAsDataURL(file)
        }
      })
      const futures = []
      for (const file of files) {
        futures.push(() => promiseCreation(file))
      }
      futures[0] = futures[0]()
      futures.reduce((l, future) => l.then(future)).then(() => {
        if (imageCount > MAX_IMAGES) {
          alert(`Can't choose more than ${MAX_IMAGES} images at once.`)
        }
        if (_actualImageNames.length) {
          setActualImageNames([...actualImageNames, ..._actualImageNames])
          setActualImages([...actualImages, ..._actualImages])
        }
      }
      )
    }
  }

  const handleStyleUpload = (e) => {
    // setInputStyleImage(e.target.value)
    const reader = new FileReader()
    const file = e.target.files[0]
    if (file.type !== 'image/jpeg') {
      alert('JPEG images only')
      return
    }
    reader.onloadend = () => {
      setInputPersonalStyle({ [file.name]: reader.result })
    }
    if (file instanceof Blob) {
      reader.readAsDataURL(file)
      setInputStyle(e.target.value)
    }
  }

  const allowDrop = (ev) => {
    ev.preventDefault()
  }

  const drop = (ev, cb) => {
    ev.preventDefault()
    try {
      if (ev.dataTransfer.files[0].type === 'image/jpeg') {
        const fakeEvent = { target: {} }
        fakeEvent.target.files = ev.dataTransfer.files
        fakeEvent.target.value = ''
        cb(fakeEvent)
      } else {
        alert('You must upload a JPEG image.')
      }
    } catch {
      alert('Make sure that you are dragging in a file that is downloaded on to your device.')
    }
  }
  // Creates a process on the backend, and returns it so that it can be added to the processes state
  const onTransform = () => {
    if (!downloading.current) {
      if (!requesting) {
        if (actualImages.length) {
          if (processes.filter((item) => item.key !== 'SUCCESS' || item.key !== 'FAILURE').length <= MAX_PROCS) {
            if (allStyles[stylesType] || stylesType === 'Your styles') {
              setRequesting(true)
              let styles
              if (stylesType === 'Your styles') {
                styles = Object.keys(personalStyles)[personalIndex]
              } else {
                styles = allStyles[stylesType][0][index]
              }
              const postData = {
                data:
                {
                  abstractionLevel,
                  style: styles,
                  images: actualImages,
                  imageNames: actualImageNames
                }
              }

              fetchApi('/api/process/create', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify(postData)
              })
                .then((res) => {
                  setRequesting(false)
                  if (res.ok) {
                    return res.json()
                  } else {
                    throw Error(`Request rejected with ${res.status}`)
                  }
                }
                )
                .then((process) => {
                  if (!process.error) {
                    handleClear()
                    setProcesses([...processes, process])
                    window.scrollTo(0, document.body.scrollHeight)
                  } else {
                    alert(process.error)
                  }
                })
                .catch((err) => alert('Couldn\'t create process, it could be your file(s) are too large. ' + err))
            } else {
              alert('Select from either our photos or your photos')
            }
          } else {
            alert(`Can not have more than ${MAX_PROCS} processes`)
          }
        } else {
          alert('You must select images you want to transform')
        }
      } else {
        alert('You are already requesting a transformation')
      }
    } else {
      alert('You can\'t transform while you are downloading')
    }
  }

  const deleteProcess = (key) => {
    // Removes process specified by key on the backend, and frontend
    return new Promise((resolve, reject) => {
      fetchApi('/api/process/delete', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ key })
      }).then((res) => {
        console.log(res)
        if (!res.ok) {
          throw Error(`Request rejected with ${res.status}`)
        }
        removeProcess(key)
        resolve()
      }
      )
        .catch((err) => {
          alert('Deletion failed: ' + err)
          reject(err)
        })
    })
  }

  const onTrainStyle = () => {
    if (inputPersonalStyle) {
      if (processes.filter((item) => item.key !== 'SUCCESS' || item.key !== 'FAILURE').length <= MAX_PROCS) {
        setRequestingTrainStyle(true)
        const postData = {
          data: {
            styleImage: inputPersonalStyle[Object.keys(inputPersonalStyle)[0]],
            styleName: Object.keys(inputPersonalStyle)[0]
          }
        }
        fetchApi('/api/action/addstyle', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(postData)
        }).then((res) => {
          if (res.ok) {
            return res.json()
          } else {
            throw Error(`Request rejected with ${res.status}`)
          }
        }
        )
          .then((process) => {
            setRequestingTrainStyle(false)
            if (!process.error) {
              setInputStyle('')
              setInputPersonalStyle({})
              setProcesses([...processes, process])
              window.scrollTo(0, document.body.scrollHeight)
            } else {
              alert(process.error)
            }
          })
          .catch(() => {
            setRequestingTrainStyle(false)
            alert('Could not train style.')
          })
      } else {
        alert(`Can not have more than ${MAX_PROCS} active processes`)
      }
    }
  }

  const deleteStyle = (key) => {
    fetchApi('/api/action/deletestyle', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        key
      })
    }).then((res) => {
      if (res.ok) {
        return res.json()
      } else {
        throw Error(`Request rejected with ${res.status}`)
      }
    }).then((data) => {
      if (data.data === 'deleted') {
        delete personalStyles[key]
        setPersonalStyles(personalStyles)
        refresh()
        if (personalIndex !== 0) {
          setPersonalIndex(personalIndex - 1)
        }
      }
    })
      .catch(() => alert('Something went wrong when trying to delete a style.'))
  }

  let _actualImages = null
  if (actualImages.length) {
    if (actualImages.length === 1) {
      _actualImages = <img src={actualImages[0]} className="inputImage" />
    } else {
      _actualImages = (
        <Carousel variant="dark" interval={null} onDrop={(e) => drop(e, handleUpload)} onDragOver={allowDrop} slide={false} className="carouselClass">
          {actualImages.map((image) => (
            <Carousel.Item
              key={image.toString().substr(40, 100)}
            >
              <img
                src={image}
                alt="input image"
                className="carouselPic"
              />
            </Carousel.Item>
          ))}
        </Carousel>
      )
    }
  }

  let _personalStyles = null
  if (Object.keys(personalStyles).length) {
    if (Object.keys(personalStyles).length === 1) {
      _personalStyles = <>
        <img src={personalStyles[Object.keys(personalStyles)[0]]} className="inputImage" />
        <div className="deleteStyleButton">
          <button className="btn btn-danger" onClick={() => deleteStyle(Object.keys(personalStyles)[0])}>
            Delete
        </button>
        </div>
      </>
    } else {
      _personalStyles = (
        <Carousel variant="dark" interval={null} activeIndex={personalIndex} onSelect={_handleSelect} slide={false} className="carouselClass">
          {Object.keys(personalStyles).map((key) => (
            <Carousel.Item
              key={key}
            >
              <img
                src={personalStyles[key]}
                alt={key}
                className="carouselPic"
              />
              <div className="deleteStyleButton2">
                <button className="btn btn-danger" onClick={() => deleteStyle(key)}>
                  Delete
                </button>
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      )
    }
  }
  let htmlfor = null
  if (!actualImages.length) {
    htmlfor = { htmlFor: 'file' }
  }
  return (
    <div className="transformationPage">
      <div className="row inputContainer justify-content-around" style={{ margin: '20px 0px' }}>
        <div className="col-lg-5 imageCol">
          <h4>Choose image/ images</h4>
          <form onSubmit={handleUpload} className="imageHolder">
            {_actualImages || <div className="inputImageEmpty"><div className="emptyBorder"></div></div>}
            {actualImages.length < MAX_IMAGES ? <label onDrop={(e) => drop(e, handleUpload)} onDragOver={allowDrop} {...htmlfor} className={`uploadImageButton ${!actualImages.length && 'btn'}`}></label> : ''}
            <div className="centeredItem" style={{ zIndex: actualImages.length ? 3 : 0, display: requesting ? 'none' : 'block' }}>
              {!actualImages.length && <p>Drop your images here or click to choose one. </p>}
              {
                actualImages.length && actualImages.length < MAX_IMAGES
                  ? (
                    <label htmlFor="file" disabled={!authenticated || requesting} className="btn btn-dark">Upload more</label>
                    )
                  : ''
              }
              {
                actualImages.length
                  ? (
                    <>
                      <label onClick={handleClear} disabled={!authenticated || requesting} className="btn btn-danger deleteInput">Clear</label>
                    </>
                    )
                  : ''
              }
              <input multiple value={inputImage} id="file" type="file" accept="image/jpeg" className="d-none" onChange={handleUpload} />
            </div>

          </form>
        </div>
        <div className="col-lg-1" style={{ position: 'relative' }}>
          {/* #FIXME add style if transformed check */}
          <div className="transformButton">
            <button className={'btn btn-primary btn-lg btn-purple'} disabled={!authenticated || !(actualImages.length && stylesType !== 'Upload style' && !requesting && (stylesType === 'Your styles' ? Object.keys(personalStyles).length : true))} onClick={onTransform}><p style={{ marginBottom: '0px' }}>Transform with style</p></button>
            <div className="form-group" style={{ marginTop: '15px' }}>
              <label htmlFor="sel1">Brush</label>
              <select className="form-control" id="sel1" value={abstractionLevel} onChange={changeAbstraction}>
                <option value="1">Image brush</option>
                <option value="2">Image color</option>
                <option value="3">Abstract</option>
                <option value="4">Fantasy</option>
                <option value="5">Brush</option>
                <option value="6">Architecture</option>
                <option value="7">Watercolor</option>
                <option value="8">Knife palette</option>
                <option value="9">Rectangles</option>
                <option value="10">Dots</option>
                <option value="11">Wipes</option>
                <option value="12">Swirls</option>
              </select>
            </div>
            {(requesting || requestingTrainStyle) && <div className="loading">Uploading to server</div>}
          </div>
        </div>
        <div className="col-lg-5 imageCol">
          <div /* style={{ width: 'auto', margin: 'auto', overflow: 'hidden' }} */>
            <Dropdown style={{ marginBottom: '.34rem', padding: '0px' }}>
              <h4 style={{ display: 'inline' }}>Currently selected style from:</h4>
              <Dropdown.Toggle variant="transparent" id="dropdown-choose-style">
                <h4 style={{ display: 'inline' }}>{stylesType}</h4>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {GUARRANTEEDTYPES.filter((item) => item !== stylesType).map((item, index) => {
                  let newLine = null
                  if (index && !(index % 2)) {
                    newLine = <><br /></>
                  }
                  return <Fragment key={item}>{newLine}<Dropdown.Item style={{ textAlign: 'center', width: 'unset', minWidth: '150px', display: 'inline-block' }} onClick={() => { setStylesType(item) }}>{item}</Dropdown.Item></Fragment>
                }
                )}
                <Dropdown.Divider />
                {Object.keys(allStyles).filter((item) => item !== stylesType).map((item, index) => {
                  let newLine = null
                  let prop = { marginRight: '0px' }
                  if (index && !(index % 2)) {
                    prop = {}
                    prop.marginLeft = '0px'
                    newLine = <><br /></>
                  }
                  return <Fragment key={item}>{newLine}<Dropdown.Item style={{ textAlign: 'center', width: 'unset', minWidth: '150px', display: 'inline-block', ...prop }} onClick={() => { setStylesType(item) }}>{item}</Dropdown.Item></Fragment>
                }
                )}
                <br />
              </Dropdown.Menu>
            </Dropdown>
            {
              Object.keys(allStyles).includes(stylesType)
                ? (
                  <div className="imageHolder">
                    <Carousel interval={null} activeIndex={index} slide={false} onSelect={handleSelect} className="carouselClass">
                      {allStyles[stylesType][0].map((key) => (
                        <Carousel.Item
                          key={key}
                        >
                          <img
                            src={`${PROXY}/api/static/image/${stylesType}/${key}`}
                            alt={allStyles[stylesType][0][key]}
                            className="carouselPic"
                          />
                          <Carousel.Caption>
                            <h4>
                              {capitalizeFirstLetter(key.replace('.jpg', ''))}
                            </h4>
                          </Carousel.Caption>
                        </Carousel.Item>
                      ))}
                    </Carousel>
                  </div>
                  )
                : stylesType === 'Your styles'
                  ? (
                    <div className="imageHolder">
                      {_personalStyles || <><div className="inputImageEmpty" style={{ border: 'solid 1px black' }} />
                        <div className="centeredItem">
                          <p>
                          {requestingPersonal ? <span className="loading">Downloading styles...</span> : "You haven't uploaded any styles yet"}
                          </p>
                        </div>
                      </>}
                    </div>
                    )
                  : stylesType === 'Upload style'
                    ? (<form className="imageHolder" onSubmit={(e) => e.preventDefault()}>
                      {Object.keys(inputPersonalStyle).length ? <img src={inputPersonalStyle[Object.keys(inputPersonalStyle)[0]]} className="inputImage" /> : <div className="inputImageEmpty"><div className="emptyBorder"></div></div>}
                      {!Object.keys(inputPersonalStyle).length ? <label onDrop={(e) => drop(e, handleStyleUpload)} onDragOver={allowDrop} htmlFor="file2" className='uploadImageButton btn'></label> : ''}
                      <div className="centeredItem">
                        {
                          Object.keys(inputPersonalStyle).length
                            ? (
                              <>
                                <button onClick={onTrainStyle} className={'btn btn-success d-block'} disabled={!authenticated || requestingTrainStyle}>Train style</button>
                                <button onClick={() => { setInputPersonalStyle({}); setInputStyle('') }} className="btn btn-danger" style={{ marginTop: '10px' }}>Clear</button>
                              </>
                              )
                            : (
                              <>
                                {!Object.keys(inputPersonalStyle).length && <p>Drop your style here or click to choose one.</p>}
                                <input multiple={false} id="file2" type="file" accept="image/jpeg" value={inputStyle} className="d-none" onChange={handleStyleUpload} />
                              </>
                              )
                        }
                      </div>
                    </form>
                      )
                    : <div className="imageHolder">
                      <div className="inputImageEmpty" style={{ border: 'solid 1px black' }} />
                    </div>
            }
          </div>
        </div>
      </div>
      <div className="container" style={{ marginTop: '25px' }}>
        {['Your styles', 'Upload style'].includes(stylesType) ? TEXT : allStyles[stylesType] && <p dangerouslySetInnerHTML={{ __html: allStyles[stylesType][1] }}></p>}
      </div>
      <div className="container outputContainer" style={{ marginTop: '50px', marginBottom: '10px' }}>
        {processes.length
          ? processes.map((process, index) => {
            if (process.type === 'transformation') {
              return <ProcessBar key={process.key} process={process} downloading={downloading} setDownloading={setDownloading} del={deleteProcess} hide={process.hide} remove={removeProcess} index={index} fetchApi={fetchApi}/>
            } else if (process.type === 'style') {
              return <StyleProcess key={process.key} process={process} hide={process.hide} set={setProcesses} procs={instantProcesses} remove={deleteProcess} index={index}/>
            }
            return null
          })
          : requestingProcesses && <div className="loading">fetching active processes</div>}
      </div>
    {downloading && <div style={{ width: '100vw', height: '100vh', backgroundColor: 'rgba(0, 0, 0, 0.5)', position: 'fixed', left: 0, right: 0, top: 0, bottom: 0, zIndex: 1000 }}><div className='downloader'><div className='spinner2'></div></div></div>
    }</div>
  )
}

export default Transformation
