import React, { useEffect, useState } from 'react'
import DataDeleter from '../../components/data_delete'
import Logout from './logout'
import Product from './Product'

const Profile = ({ _token, name, logoutUser, fetchApi }) => {
  const [styleCreates, setStyleCreates] = useState(null)
  const [transformations, setTransformations] = useState(null)

  useEffect(() => {
    if (_token) {
      fetchApi('/api/action/getconfig').then((res) => {
        if (res.ok) {
          return res.json()
        } else {
          throw Error(`Something went wrong ${res.status}`)
        }
      }).then((data) => {
        setTransformations(data.transforms)
        setStyleCreates(data.style_creates)
      }).catch((err) => {
        console.log(err)
      })
    }
  }, [_token])

  return (
    <div className="profile">
        <h3 style={{ marginTop: '80px' }}>{name}</h3>
        <div style={{ marginTop: '20px' }}>
          {
            _token && <Logout logoutUser={logoutUser}/>
          }
          <DataDeleter token={_token} fetchApi={fetchApi} logoutUser={logoutUser} />
        </div>
          {styleCreates != null
            ? <>
          <div className="container ">
            <div className="row justify-content-center">
            <div className="col-lg-3 card credits">
              <div className="card-body">
                <h5 className="card-title">Top up your account</h5>
                <p className="card-text">To run your transformations we use cloud servers with expensive GPUs. You can support us through PayPal.</p>
                <p className="card-text">High res transformations remaining: {transformations}</p>
                <p className="card-text">Style creations remaining: {styleCreates}</p>
              </div>
            </div>
            <div className="col-lg-3 card credits">
              <div className="card-body">
                <h5 className="card-title">Get transformations</h5>
                <Product updater={setTransformations} product={{ description: 'Purchase 20 additional high res transformation for $20.', price: 20, amount: 20, name: 'few_transforms' }}/>
              </div>
            </div>
            <div className="col-lg-3 card credits">
            <div className="card-body">
                <h5 className="card-title">Get style creations</h5>
                <Product updater={setStyleCreates} product={{ description: 'Purchase 10 additional style creations for $25.', price: 25, amount: 10, name: 'few_styles' }}/>
              </div>
            </div>
            </div>
          </div>
          </>
            : <div className='spinner'>

              </div>}
    </div>
  )
}

export default Profile
