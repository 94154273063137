import React, { useRef, useEffect } from 'react'
function Product ({ product, updater }) {
  const paypalRef = useRef()

  useEffect(() => {
    window.paypal
      .Buttons({
        createOrder: (data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                description: product.description,
                amount: {
                  currency_code: 'USD',
                  value: product.price
                }
              }
            ]
          })
        },
        onApprove: (data, actions) => {
          return fetch('/api/user/paypal/capture', {
            method: 'POST',
            headers: {
              'content-type': 'application/json'
            },
            body: JSON.stringify({
              orderID: data.orderID,
              product_name: product.name
            })
          }).then((res) => {
            return res.json()
          }).then((details) => {
            if (!details.error) {
              alert('Transaction successful')
              updater(product.amount)
            } else {
              alert('Transaction failed')
            }
          })
        },
        onError: err => {
          console.error(err)
        }
      })
      .render(paypalRef.current)
  }, [product.description, product.price])

  return (
      <>
        <p className="card-text">{product.description}</p>
        <div ref={paypalRef} />
      </>
  )
}

export default Product
