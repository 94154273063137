/* eslint-disable react/prop-types */
import React, { useEffect, useRef } from 'react'
import { propTypes } from 'react-bootstrap/esm/Image'
import button from './pngfind.com-cross-png-609333.png'
import './transformation.css'

const ProcessBar = ({ process, del, downloading, setDownloading, index, remove, hide, fetchApi }) => {
  async function download () {
    if (!downloading.current) {
      setDownloading(true)
      for (let i = 0; i < process.data.imageCount; i++) {
        const name = process.data.result[i]
        const link = document.createElement('a')
        link.style.display = 'none'
        let downloaded = 0
        fetchApi('/api/static/transformedimage', { body: JSON.stringify({ data: { storage: process.data.storage_id, file: name, key: process.key } }), method: 'POST' })
          .then((res) => {
            if (res.ok) {
              return res.blob()
            }
          }).then((blob) => {
            const objectURL = URL.createObjectURL(blob)
            link.setAttribute('href', objectURL)
            link.setAttribute('download', name)
            link.click()
            downloaded++
            if (downloaded === process.data.imageCount) {
              remove(process.key)
              setDownloading(false)
            }
          }).catch(() => {
            setDownloading(false)
          })
      }
    } else {
      alert("You can't download when you are already downloading")
    }
  }
  const ref = useRef()
  useEffect(() => {
    ref.current.scrollIntoView({ behavior: 'smooth' })
  }, [])

  const deletionButton = <button className="deleteButton" style={{ background: 'transparent', position: 'absolute', right: '20px' }} onClick={() => { del(process.key) }} ><img src={button} height="20px" width="20px"></img></button>
  let processDisplay = ''
  if (process.data.status === 'PENDING') {
    processDisplay = (
    <div style={{ width: '100%' }} className="border border-warning procborder">
      Queued {process.data.imageCount} image{process.data.imageCount > 1 ? 's' : ''} to be processed with style: {process.data.style}. Process id: {process.key}
    </div>
    )
  } else if (process.data.status === 'STARTED') {
    processDisplay = (
    <div className="border border-primary procborder" style={{ width: '100%' }}>
      Processing {process.data.imageCount} image{process.data.imageCount > 1 ? 's' : ''} with style: {process.data.style}. Process id: {process.key}
    </div>
    )
  } else if (process.data.status === 'FINALIZING') {
    processDisplay = (
    <div className="border border-info procborder" style={{ width: '100%' }}>
      Finalizing {process.data.imageCount} image{process.data.imageCount > 1 ? 's' : ''} with style: {process.data.style}. Process id: {process.key}
    </div>
    )
  } else if (process.data.status === 'FAILURE' || process.data.status === 'failed_upload') {
    processDisplay = (
      <div className="border border-danger procborder" style={{ width: '100%', position: 'relative' }}>
        Process {process.key} failed.
        {deletionButton}
      </div>
    )
  } else if (process.data.status === 'SUCCESS') {
    processDisplay = (
      <div className="border border-success procborder" style={{ width: '100%' }}>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css" />
        Processed {process.data.imageCount} image{process.data.imageCount > 1 ? 's' : ''} with style: {process.data.style}
        <button className="btn" onClick={download}><i className="fa fa-download d-inline"></i> Download</button>
      </div>
    )
  }
  return (
        <div ref={ref} className={`barcontainer ${hide ? 'hide' : ''}`} style={{ transform: `translateY(calc(-${100 * index}% - 10px))`, opacity: '0' }}>
          {processDisplay}
        </div>
  )
}

ProcessBar.propTypes = {
  process: propTypes.object
}

export default React.memo(ProcessBar)
