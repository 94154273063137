import React, { useEffect, useRef } from 'react'
// import ReactFacebookLogin from 'react-facebook-login'
// import GoogleLogin from 'react-google-login'
import './login.css'

// eslint-disable-next-line react/prop-types
const Login = ({ cancel, FBListener, handleLoginClose }) => {
  const googleButton = useRef()

  useEffect(() => {
    const handler = (e) => (e.key === 'Escape') && cancel()
    document.addEventListener('keydown', handler)

    window.google.accounts.id.renderButton(googleButton.current, {
      theme: 'outline',
      size: 'large',
      width: 205,
      click_listener: () => handleLoginClose()
    })

    return () => {
      document.removeEventListener('keydown', handler)
    }
  }, [])
  return (
    <div className="popup-background">
      <div className="card loginform" style={{ position: 'fixed' }}>
          <button onClick={cancel}className="crossbutton btn btn-sm ml-auto mr-3">
           <svg height="18" width="18" viewBox="0 0 24 24" aria-hidden="true" aria-label="" role="img"><path d="M15.18 12l7.16-7.16c.88-.88.88-2.3 0-3.18-.88-.88-2.3-.88-3.18 0L12 8.82 4.84 1.66c-.88-.88-2.3-.88-3.18 0-.88.88-.88 2.3 0 3.18L8.82 12l-7.16 7.16c-.88.88-.88 2.3 0 3.18.44.44 1.01.66 1.59.66.58 0 1.15-.22 1.59-.66L12 15.18l7.16 7.16c.44.44 1.01.66 1.59.66.58 0 1.15-.22 1.59-.66.88-.88.88-2.3 0-3.18L15.18 12z"></path></svg>
          </button>
          <div className="card-body">
          <h5 className="card-title">Welcome to Dionyziuz!</h5>
          <hr></hr>
              {/* <div> */}
              {/* className="loginbuttons btn btn-outline-dark m-auto" role="button" style={{ textTransform: 'none'}} */}
              <div className='googlebutton' ref={googleButton}>
              </div>
              {/* </div> */}
              <div className="disclaimertext mt-10"><span> If you continue, you agree with the <a rel="noopener noreferrer" target="_blank" data-test-id="tos" href="/tos">Terms of service</a>, including the <a target="_blank" rel="noopener noreferrer" data-test-id="privacy" href="/tos#privacypolicy">Privacy policy</a> and the <a target="_blank" rel="noopener noreferrer" data-test-id="cookies" href="/tos#cookies">Cookie usage</a> of Dionyziuz. </span></div>
          </div>
      </div>
    </div>
  )
}

export default Login
