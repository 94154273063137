import React from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const DataDeleter = ({ token, logoutUser, fetchApi }) => {
  const history = useHistory()
  const deleteData = () => {
    if (confirm('Are you sure you want to delete all your data, this includes your remaining transformations')) {
      fetchApi('/api/user/deletedata', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      }).then((res) => {
        if (res.ok) {
          return res.json()
        } else {
          throw Error
        }
      }).then((data) => {
        alert('Data was deleted')
        logoutUser()
        history.push('/')
      }).catch(() => {
        alert('Data deletion failed')
      })
    }
  }
  if (!token) {
    return null
  }

  return (
        <button className="btn btn-danger" onClick={deleteData}>
        Delete all my data
        </button>
  )
}

export default DataDeleter
