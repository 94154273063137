/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useRef, useState } from 'react'
import Controlledcarousel from './controlledcarousel'
import './IOShowcase.css'

function importAll (r, dynamic, carouselState) {
  const images = {
    input: [],
    style: [],
    output: []
  }
  try {
    r.keys().forEach((item, index) => {
      if (dynamic) {
        if (index >= (3 * carouselState) + 6) {
          throw Error()
        }
      }
      const validateKey = item.substr(2)
      if (/^input/.test(validateKey.split('/')[1])) {
        images.input.push(r(item))
      } else if (/^style/.test(validateKey.split('/')[1])) {
        images.style.push(r(item))
      } else if (/^output/.test(validateKey.split('/')[1])) {
        images.output.push(r(item))
      }
    })
  } catch {
  }
  return images
}

export default React.forwardRef(function IOCarousel (props, ref) {
  const [carouselState, setCarouselState] = useState(0)
  const requirements = require.context('./images', true, /\.(png|jpe?g|svg)$/)
  const [dynamic, setDynamic] = useState(true)

  const val = useMemo(() => requirements.keys().length / 3, [])
  const images = importAll(requirements, dynamic, carouselState)

  if (carouselState === val) {
    setDynamic(false)
    setCarouselState(0)
  }
  const handleSelect = (selectedIndex, e) => {
    setCarouselState(selectedIndex)
  }

  useEffect(() => {
    const carouselChange = setTimeout(() => {
      setCarouselState(carouselState + 1)
    }, 10000)
    return () => {
      clearInterval(carouselChange)
    }
  }, [carouselState])
  // const [visible, setVisible] = useState(true)
  const visible = true
  const _ref = useRef()
  // useEffect(() => {
  //   if (_ref.current) {
  //     const options = { root: null, rootMargin: '0px 0px -300px 0px' }
  //     const observer = new IntersectionObserver((entries, observer) => {
  //       entries.forEach(entry => {
  //         if (entry.isIntersecting) {
  //           setVisible(true)
  //           observer.unobserve(_ref.current)
  //         }
  //       })
  //     }, options)
  //     const timeout = setTimeout(() => {
  //       observer.observe(_ref.current)
  //     }, 500)
  //     return () => {
  //       clearTimeout(timeout)
  //       observer.disconnect()
  //     }
  //   }
  // }, [])

  return (
      <button className="carouselButton" ref={_ref} style={{ color: 'inherit', textDecoration: 'none', marginTop: '30px', marginBottom: '10px' }} onClick={() => { setCarouselState(carouselState + 1) }}>
      <div className="row justify-content-around carouselContainers" style={{ margin: '0px', paddingTop: '2px', paddingBottom: '20px' }}>
          <Controlledcarousel onSelect={handleSelect} visible={visible} index={carouselState} title="Your photo" images={images.input}/>
          <Controlledcarousel onSelect={handleSelect} visible={visible} index={carouselState} title="Apply any style" images={images.style}/>
          <Controlledcarousel onSelect={handleSelect} visible={visible} index={carouselState} title="Create your own artwork" images={images.output}/>
      </div>
      </button>
  )
})
